import React from "react"
import Layout from "../../components/layout/layout"
import InnerWrapper from "../../components/layout/innerWrapper"
import { Row, Col } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import SEO from "../../components/seo"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import AboutSidebar from "../../components/layout/about-sidebar"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "about/walpole-ceo.jpg" }) {
        childImageSharp {
          id
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout className="main inner-page-with-banner">
      <SEO
        title={`Message from our CEO | The Walpole Difference | Walpole Outdoors`}
        description={`Learn all about the Walpole Outdoors difference, and our top-quality processes including manufacturing, design consultation, and installation.`}
      />

      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about">About</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about/message-ceo">Message from our CEO</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row className="generalcontent">
          <Col md={3}>
            <AboutSidebar></AboutSidebar>
          </Col>
          <Col md={9}>
            <h2 className="generalheader">
              Coming Soon
            </h2>

           
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default AboutPage
